import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const EmptyRouterView = () => import(/* webpackChunkName: "exoty" */ '@/components/EmptyRouter.vue');
const PicwordSetting = () => import(/* webpackChunkName: "exoty" */ '@/modules/picword/setting/view/edit.vue');

const PicwordMapAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/picword/map/view/add.vue');
const PicwordMapEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/picword/map/view/edit.vue');
const PicwordMapDetail = () => import(/* webpackChunkName: "exoty" */ '@/modules/picword/map/view/detail.vue');
const PicwordMapList = () => import(/* webpackChunkName: "exoty" */ '@/modules/picword/map/view/index.vue');

const PicwordPetAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/picword/pet/view/add.vue');
const PicwordPetEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/picword/pet/view/edit.vue');
const PicwordPetDetail = () => import(/* webpackChunkName: "exoty" */ '@/modules/picword/pet/view/detail.vue');
const PicwordPetList = () => import(/* webpackChunkName: "exoty" */ '@/modules/picword/pet/view/index.vue');

export default [
    {
        path: '/app/picword/',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'setting',
                name: 'PicwordSetting',
                component: PicwordSetting,
                meta: { requiresAuth: true }
            },

            {
                path: 'map',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'PicwordMapList',
                        component: PicwordMapList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'PicwordMapAdd',
                        component: PicwordMapAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/edit',
                        name: 'PicwordMapEdit',
                        component: PicwordMapEdit,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/detail',
                        name: 'PicwordMapDetail',
                        component: PicwordMapDetail,
                        meta: { requiresAuth: true }
                    }
                ]
            },

            {
                path: 'pet',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'PicwordPetList',
                        component: PicwordPetList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'PicwordPetAdd',
                        component: PicwordPetAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/edit',
                        name: 'PicwordPetEdit',
                        component: PicwordPetEdit,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/detail',
                        name: 'PicwordPetDetail',
                        component: PicwordPetDetail,
                        meta: { requiresAuth: true }
                    }
                ]
            }
        ]
    }
];

export const PICWORD_ROUTES = {
    MAP: {
        INDEX: () => router.resolve({ name: 'PicwordMapList' }),
        ADD: () => router.resolve({ name: 'PicwordMapAdd' }),
        EDIT: (id: any) => router.resolve({ name: 'PicwordMapEdit', params: { id } }),
        DETAIL: (id: any) => router.resolve({ name: 'PicwordMapDetail', params: { id } })
    },

    PET: {
        INDEX: () => router.resolve({ name: 'PicwordPetList' }),
        ADD: () => router.resolve({ name: 'PicwordPetAdd' }),
        EDIT: (id: any) => router.resolve({ name: 'PicwordPetEdit', params: { id } }),
        DETAIL: (id: any) => router.resolve({ name: 'PicwordPetDetail', params: { id } })
    }
};
