const versionModule = {
    namespaced: true,
    state: {
        page: 1,
        store: 0
    },
    mutations: {
        setPage: (state: any, page: number): void => {
            state.page = page;
        },
        setStore: (state: any, store: number): void => {
            state.store = store;
        }
    },
    actions: {
        changePage: (context: any, page: number): void => {
            context.commit('setPage', page);
        },
        setStore: (context: any, store: number): void => {
            context.commit('setStore', store);
        }
    },
    getters: {
        page: (state: any): number => state.page,
        store: (state: any): number => state.store
    }
};

export default versionModule;
