import http from '@/utils/axios';

export const applicationService = {
    account: {
        list({ offset, limit, type, criteria }: any) {
            return http.doGet('admin/app/account', { offset, limit, type, criteria });
        },

        get(accountId: any) {
            return http.doGet('admin/app/account/' + accountId);
        },

        delete(accountId: any) {
            return http.doPut('admin/app/account/' + accountId + '/delete');
        },

        undelete(accountId: any) {
            return http.doDelete('admin/app/account/' + accountId + '/delete');
        },

        isBanned(accountId: any) {
            return http.doGet('admin/app/account/' + accountId + '/ban');
        },

        ban(accountId: any) {
            return http.doPut('admin/app/account/' + accountId + '/ban');
        },

        unban(accountId: any) {
            return http.doDelete('admin/app/account/' + accountId + '/ban');
        }
    },

    app: {
        list() {
            return http.doGet('/admin/app');
        },

        get(appId: any) {
            return http.doGet('/admin/app/' + appId);
        },

        update(
            appId: any,
            name: any,
            currency: any,
            creditInit: any,
            friendLimit: any,
            popupRate: any,
            winsForRating: any,
            bindSuggestTime: any,
            remindApple: any,
            remainVipMinutes: any,
            vipTermMinutes: any,
            creditWelcomeLimit: any,
            thumbnail: any
        ) {
            return http.doPut(
                '/admin/app/' + appId,
                {
                    name: name,
                    currency: currency,
                    creditInit: creditInit,
                    friendLimit: friendLimit,
                    popupRate: popupRate,
                    winsForRating: winsForRating,
                    bindSuggestTime: bindSuggestTime,
                    remindApple: remindApple,
                    remainVipMinutes: remainVipMinutes,
                    vipTermMinutes: vipTermMinutes,
                    creditWelcomeLimit: creditWelcomeLimit
                },
                {
                    thumbnail: thumbnail
                }
            );
        },

        create(
            name: any,
            currency: any,
            creditInit: any,
            friendLimit: any,
            popupRate: any,
            winsForRating: any,
            bindSuggestTime: any,
            remindApple: any,
            remainVipMinutes: any,
            vipTermMinutes: any,
            creditWelcomeLimit: any,
            thumbnail: any
        ) {
            return http.doPost(
                '/admin/app',
                {
                    name: name,
                    currency: currency,
                    creditInit: creditInit,
                    friendLimit: friendLimit,
                    popupRate: popupRate,
                    winsForRating: winsForRating,
                    bindSuggestTime: bindSuggestTime,
                    remindApple: remindApple,
                    remainVipMinutes: remainVipMinutes,
                    vipTermMinutes: vipTermMinutes,
                    creditWelcomeLimit: creditWelcomeLimit
                },
                {
                    thumbnail: thumbnail
                }
            );
        },

        undelete(appId: any) {
            return http.doDelete('/admin/app/' + appId + '/delete');
        },

        remove(appId: any) {
            return http.doPut('/admin/app/' + appId + '/delete');
        }
    }
};
