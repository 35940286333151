import { defaultStorePagingProps } from '@/store/mixins';

export interface SystemItemFilter {
    forAppId: string;
    name: string;
}

const itemModule = {
    namespaced: true,
    state: {
        ...defaultStorePagingProps.state,
        filter: { forAppId: '', name: '' } as SystemItemFilter
    },
    mutations: {
        ...defaultStorePagingProps.mutations,
        setFilter: (state: any, filter: SystemItemFilter): void => {
            state.filter = filter;
        }
    },
    actions: {
        ...defaultStorePagingProps.actions,
        setFilter: (context: any, filter: SystemItemFilter): void => {
            context.commit('setFilter', filter);
        }
    },
    getters: {
        ...defaultStorePagingProps.getters,
        filter: (state: any): SystemItemFilter => state.filter
    }
};

export default itemModule;
