import { defaultStorePagingProps } from '@/store/mixins';

export interface AvatarDefaultFilter {
    type: string;
}

const avatarDefaultModule = {
    namespaced: true,
    state: {
        ...defaultStorePagingProps.state,
        filter: { type: '' } as AvatarDefaultFilter
    },
    mutations: {
        ...defaultStorePagingProps.mutations,
        setFilter: (state: any, filter: AvatarDefaultFilter): void => {
            state.filter.type = filter.type;
        }
    },
    actions: {
        ...defaultStorePagingProps.actions,
        changeFilter: (context: any, filter: AvatarDefaultFilter): void => {
            context.commit('setFilter', filter);
        }
    },
    getters: {
        ...defaultStorePagingProps.getters,
        filter: (state: any): AvatarDefaultFilter => state.filter
    }
};

export default avatarDefaultModule;
