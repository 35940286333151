import App from '@/app/app.vue';
import '@/index.js';
import '@/index.scss';
import vDialog from '@/plugins/dialog/v-dialog';
import eventBus from '@/plugins/event-bus';
import vModal from '@/plugins/modal/v-modal';
import router from '@/router';
import store from '@/store';
import { createApp } from 'vue';
import Toast, { PluginOptions } from 'vue-toastification';
import { registerModules } from './utils/registerModules';

import AccountModule from '@/modules/account';
import ActivityModule from '@/modules/activity';
import AppleModule from '@/modules/apple';
import ApplicationsModule from '@/modules/apps';
import ArenaModule from '@/modules/arena';
import SysAvatarModule from '@/modules/avatar';
import BadWordModule from '@/modules/badword';
import BonusModule from '@/modules/bonus';
import BoosterModule from '@/modules/booster';
import ChallengeModule from '@/modules/challenge';
import ChatModule from '@/modules/chat';
import CreditTicketModule from '@/modules/credit/ticket';
import DuplicateModule from '@/modules/duplicate';
import FacebookModule from '@/modules/facebook';
import ForgotPasswordModule from '@/modules/forgot-password';
import GameModule from '@/modules/game';
import GiftModule from '@/modules/gift';
import GoogleModule from '@/modules/google';
import IAPModule from '@/modules/iap';
import ItemModule from '@/modules/item';
import LeagueModule from '@/modules/league';
import LocaleModule from '@/modules/locale';
import LoginModule from '@/modules/login';
import MailModule from '@/modules/mail';
import MainModule from '@/modules/main';
import MaintenanceModule from '@/modules/maintenance';
import MessageModule from '@/modules/message';
import NewsModule from '@/modules/news';
import PicwordModule from '@/modules/picword';
import PiggybankModule from '@/modules/piggybank';
import ProfileModule from '@/modules/profile';
import ProgressModule from '@/modules/progress';
import PromotionModule from '@/modules/promotion';
import QuestModule from '@/modules/quest';
import RFMModule from '@/modules/rfm';
import RoleModule from '@/modules/role';
import ScratchModule from '@/modules/scratch';
import ShopModule from '@/modules/shop';
import SlotModule from '@/modules/slot';
import StarterPackModule from '@/modules/starter/pack';
import StripeModule from '@/modules/stripe';
import SubscriptionModule from '@/modules/subscription';
import SurpriseModule from '@/modules/surprise';
import TotemModule from '@/modules/totem';
import TournamentModule from '@/modules/tournament';
import TransactionModule from '@/modules/transaction';
import TranslationModule from '@/modules/translation';
import VersionModule from '@/modules/version';

registerModules({
    Main: MainModule,
    Role: RoleModule,
    Item: ItemModule,
    SysAvatar: SysAvatarModule,
    Arena: ArenaModule,
    Apple: AppleModule,
    Account: AccountModule,
    Activity: ActivityModule,
    BadWord: BadWordModule,
    Bonus: BonusModule,
    Booster: BoosterModule,
    Challenge: ChallengeModule,
    Chat: ChatModule,
    CreditTicket: CreditTicketModule,
    Duplicate: DuplicateModule,
    Facebook: FacebookModule,
    Applications: ApplicationsModule,
    Profile: ProfileModule,
    Game: GameModule,
    Gift: GiftModule,
    Google: GoogleModule,
    IAP: IAPModule,
    League: LeagueModule,
    Locale: LocaleModule,
    Mail: MailModule,
    Maintenance: MaintenanceModule,
    Message: MessageModule,
    News: NewsModule,
    Picword: PicwordModule,
    Piggybank: PiggybankModule,
    Progress: ProgressModule,
    Promotion: PromotionModule,
    Quest: QuestModule,
    RFM: RFMModule,
    Scratch: ScratchModule,
    Shop: ShopModule,
    Slot: SlotModule,
    StarterPack: StarterPackModule,
    Stripe: StripeModule,
    Subscription: SubscriptionModule,
    Surprise: SurpriseModule,
    Totem: TotemModule,
    Tournament: TournamentModule,
    Transaction: TransactionModule,
    Translation: TranslationModule,
    Version: VersionModule,
    ForgotPassword: ForgotPasswordModule,
    Login: LoginModule
});

const options: PluginOptions = {
    timeout: 1000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: true,
    hideProgressBar: false,
    closeButton: 'button',
    icon: true,
    rtl: false
};

createApp(App).use(store).use(router).use(Toast, options).use(vModal).use(vDialog).use(eventBus).mount('#app');
