import { defaultStorePagingProps } from '@/store/mixins';

export type StorePromotionItemFilter = {
    type: number;
    status: number;
    rfmPersonaId: number;
};

const promotionItemModule = {
    namespaced: true,
    state: {
        ...defaultStorePagingProps.state,
        filter: { type: 0, status: -1, rfmPersonaId: null } as StorePromotionItemFilter
    },
    mutations: {
        ...defaultStorePagingProps.mutations,
        setFilter: (state: any, filter: StorePromotionItemFilter): void => {
            state.filter = filter;
        }
    },
    actions: {
        ...defaultStorePagingProps.actions,
        setFilter: (context: any, filter: StorePromotionItemFilter): void => {
            context.commit('setFilter', filter);
        }
    },
    getters: {
        ...defaultStorePagingProps.getters,
        filter: (state: any): StorePromotionItemFilter => state.filter
    }
};

export default promotionItemModule;
