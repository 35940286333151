const localeModule = {
    namespaced: true,
    state: {
        page: 1,
        criteria: ''
    },
    mutations: {
        setPage: (state: any, page: number): void => {
            state.page = page;
        },
        setCriteria: (state: any, criteria: string): void => {
            state.criteria = criteria;
        }
    },
    actions: {
        changePage: (context: any, page: number): void => {
            context.commit('setPage', page);
        },
        setCriteria: (context: any, criteria: string): void => {
            context.commit('setCriteria', criteria);
        }
    },
    getters: {
        page: (state: any): number => state.page,
        criteria: (state: any): string => state.criteria
    }
};

export default localeModule;
