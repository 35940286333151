import { defaultStorePagingProps } from '@/store/mixins';

export interface StoreTranslationFilter {
    locale: string;
    filterType: number;
    criteria: string;
}

const translationModule = {
    namespaced: true,
    state: {
        ...defaultStorePagingProps.state,
        filter: {
            locale: null,
            filterType: 0,
            criteria: null
        } as StoreTranslationFilter
    },
    mutations: {
        ...defaultStorePagingProps.mutations,
        setFilter: (state: any, filter: StoreTranslationFilter): void => {
            state.filter = filter;
        }
    },
    actions: {
        ...defaultStorePagingProps.actions,
        setFilter: (context: any, filter: StoreTranslationFilter): void => {
            context.commit('setFilter', filter);
        }
    },
    getters: {
        ...defaultStorePagingProps.getters,
        filter: (state: any): any => state.filter
    }
};

export default translationModule;
