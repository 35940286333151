import http from '@/utils/axios';

export const roleService = {
    system: {
        list: async ({ offset, limit }: any) => {
            return http.doGet('/admin/role/account', { offset, limit });
        },

        get: async () => {
            return http.doGet('admin/role');
        },

        add: async (accountId: number, email: string, role: string) => {
            // post method
            return http.doPut('/admin/role/account', {
                accountId: accountId,
                email: email,
                role: role
            });
        },

        remove: async (accountId: number, role: string) => {
            return http.doDelete('admin/role/account/' + accountId + '/' + role);
        }
    },

    app: {
        list: ({ offset, limit }: any) => {
            return http.doGet('/admin/role/app/account', { offset, limit });
        },

        get: async () => {
            return http.doGet('admin/role/app');
        },

        add: async (accountId: number, email: string, role: string) => {
            // post method
            return http.doPut('/admin/role/app/account', {
                accountId: accountId,
                email: email,
                role: role
            });
        },

        remove: async (accountId: number, role: string) => {
            return http.doDelete('admin/role/app/account/' + accountId + '/' + role);
        }
    }
};
