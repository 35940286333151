import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import accountModule from './account';
import accountActivityModule from './account/activity';
import accountTransactionModule from './account/transaction';
import activityModule from './activity';
import applicationModule from './applications';
import arenaItemModule from './arena/item';
import arenaRankModule from './arena/item/rank';
import authModule from './auth';
import avatarBackgroundModule from './avatar/background';
import avatarBundleModule from './avatar/bundle';
import avatarCategoryModule from './avatar/category';
import avatarDefaultModule from './avatar/default';
import avatarItemModule from './avatar/item';
import avatarImageModule from './avatar/item/image';
import boosterModule from './booster';
import duplicateCycleModule from './duplicate/cycle';
import duplicateRankModule from './duplicate/cycle/item/rank';
import duplicateItemModule from './duplicate/item';
import itemModule from './item';
import localeModule from './locale';
import maintenanceModule from './maintenance';
import newsModule from './news';
import picwordMapModule from './picword/map';
import picwordPetModule from './picword/pet';
import progressModule from './progress';
import promotionBoosterModule from './promotion/booster';
import promotionCodeModule from './promotion/code';
import promotionCycleModule from './promotion/cycle';
import promotionItemModule from './promotion/item';
import promotionPlanModule from './promotion/plan';
import promotionPrototypeModule from './promotion/prototype';
import rfmModule from './rfm';
import appRoleModule from './role/app';
import systemRoleModule from './role/system';
import ticketModule from './scratch/ticket';
import subscriptionItemModule from './subscription/item';
import surpriseCategoryModule from './surprise/category';
import surpriseItemModule from './surprise/category/item';
import tournamentCycleModule from './tournament/cycle';
import tournamentLeaderboardModule from './tournament/leaderboard';
import transactionModule from './transaction';
import translationModule from './translation';
import uiModule from './ui';
import versionModule from './version';

export const STORE_MODULES = {
    Auth: { name: 'auth', module: authModule },
    Ui: { name: 'ui', module: uiModule },
    Ticket: { name: 'ticket', module: ticketModule },
    AppRole: { name: 'appRole', module: appRoleModule },
    SystemRole: { name: 'systemRole', module: systemRoleModule },
    Application: { name: 'application', module: applicationModule },
    SubscriptionItem: { name: 'subscriptionItem', module: subscriptionItemModule },
    PromotionItem: { name: 'promotionItem', module: promotionItemModule },
    PromotionCode: { name: 'promotionCode', module: promotionCodeModule },
    PromotionPrototype: { name: 'promotionPrototype', module: promotionPrototypeModule },
    PromotionBooster: { name: 'promotionBooster', module: promotionBoosterModule },
    PromotionPlan: { name: 'promotionPlan', module: promotionPlanModule },
    PromotionCycle: { name: 'promotionCycle', module: promotionCycleModule },
    Version: { name: 'version', module: versionModule },
    Locale: { name: 'locale', module: localeModule },
    Translation: { name: 'translation', module: translationModule },
    Transaction: { name: 'transaction', module: transactionModule },
    News: { name: 'news', module: newsModule },
    Account: { name: 'account', module: accountModule },
    AccountActivity: { name: 'accountActivity', module: accountActivityModule },
    AccountTransaction: { name: 'accountTransaction', module: accountTransactionModule },
    SurpriseCategory: { name: 'surpriseCategory', module: surpriseCategoryModule },
    SurpriseItem: { name: 'surpriseItem', module: surpriseItemModule },
    Progress: { name: 'progress', module: progressModule },
    DuplicateItem: { name: 'duplicateItem', module: duplicateItemModule },
    DuplicateCycle: { name: 'duplicateCycle', module: duplicateCycleModule },
    DuplicateRank: { name: 'duplicateRank', module: duplicateRankModule },
    TournamentLeaderboard: { name: 'tournamentLeaderboard', module: tournamentLeaderboardModule },
    TournamentCycle: { name: 'tournamentCycle', module: tournamentCycleModule },
    Item: { name: 'item', module: itemModule },
    ArenaItem: { name: 'arenaItem', module: arenaItemModule },
    ArenaRank: { name: 'arenaRank', module: arenaRankModule },
    AvatarDefault: { name: 'avatarDefault', module: avatarDefaultModule },
    AvatarBundle: { name: 'avatarBundle', module: avatarBundleModule },
    AvatarCategory: { name: 'avatarCategory', module: avatarCategoryModule },
    AvatarItem: { name: 'avatarItem', module: avatarItemModule },
    AvatarImage: { name: 'avatarImage', module: avatarImageModule },
    AvatarBackground: { name: 'avatarBackground', module: avatarBackgroundModule },
    Maintenance: { name: 'maintenance', module: maintenanceModule },
    Rfm: { name: 'rfm', module: rfmModule },
    Activity: { name: 'activity', module: activityModule },
    Booster: { name: 'booster', module: boosterModule },
    PicwordMap: { name: 'picwordMap', module: picwordMapModule },
    PicwordPet: { name: 'picwordPet', module: picwordPetModule }
} as const;

export default createStore({
    modules: Object.fromEntries(Object.values(STORE_MODULES).map(({ name, module }) => [name, module])),
    plugins: [
        createPersistedState({
            key: 'vuex',
            reducer(state: any) {
                return !state.auth.token?.length ? {} : state;
            }
        })
    ]
});
