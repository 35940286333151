import { defaultStorePagingProps } from '@/store/mixins';

export interface ArenaItemRankFilter {
    accountId: number;
}

const arenaRankModule = {
    namespaced: true,
    state: {
        ...defaultStorePagingProps.state,
        filter: { accountId: null } as ArenaItemRankFilter
    },
    mutations: {
        ...defaultStorePagingProps.mutations,
        setFilter: (state: any, filter: ArenaItemRankFilter): void => {
            state.filter = filter;
        }
    },
    actions: {
        ...defaultStorePagingProps.actions,
        setFilter: (context: any, filter: ArenaItemRankFilter): void => {
            context.commit('setFilter', filter);
        }
    },
    getters: {
        ...defaultStorePagingProps.getters,
        filter: (state: any): ArenaItemRankFilter => state.filter
    }
};

export default arenaRankModule;
