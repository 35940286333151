import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const EmptyRouterView = () => import(/* webpackChunkName: "exoty" */ '@/components/EmptyRouter.vue');

const StarterPackList = () => import(/* webpackChunkName: "exoty" */ '@/modules/starter/pack/view/index.vue');
const StarterPackAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/starter/pack/view/add.vue');
const StarterPackEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/starter/pack/view/edit.vue');
const StarterPackDetail = () => import(/* webpackChunkName: "exoty" */ '@/modules/starter/pack/view/detail.vue');

const StarterPackRewardList = () =>
    import(/* webpackChunkName: "exoty" */ '@/modules/starter/pack/reward/view/index.vue');
const StarterPackRewardAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/starter/pack/reward/view/add.vue');

export default [
    {
        path: '/app/starter/pack',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'StarterPackList',
                component: StarterPackList,
                meta: { requiresAuth: true }
            },
            {
                path: 'add',
                name: 'StarterPackAdd',
                component: StarterPackAdd,
                meta: { requiresAuth: true }
            },
            {
                path: ':id/edit',
                name: 'StarterPackEdit',
                component: StarterPackEdit,
                meta: { requiresAuth: true }
            },
            {
                path: ':id/detail',
                name: 'StarterPackDetail',
                component: StarterPackDetail,
                meta: { requiresAuth: true }
            },
            {
                path: ':starterPackId/reward/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'StarterPackRewardList',
                        component: StarterPackRewardList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'StarterPackRewardAdd',
                        component: StarterPackRewardAdd,
                        meta: { requiresAuth: true }
                    }
                ]
            }
        ]
    }
];

export const STARTER_PACK_ROUTER = {
    INDEX: () => router.resolve({ name: 'StarterPackList' }),
    ADD: () => router.resolve({ name: 'StarterPackAdd' }),
    EDIT: (id: any) => router.resolve({ name: 'StarterPackEdit', params: { id: id } }),
    DETAIL: (id: any) => router.resolve({ name: 'StarterPackDetail', params: { id: id } }),

    REWARD: {
        INDEX: (starterPackId: any) => router.resolve({ name: 'StarterPackRewardList', params: { starterPackId } }),
        ADD: (starterPackId: any) => router.resolve({ name: 'StarterPackRewardAdd', params: { starterPackId } })
    }
};
