import { defaultStorePagingProps } from '@/store/mixins';

export interface StoreActivityFilter {
    accountId: number;
    action: number;
    feature: string;
}
const activityModule = {
    namespaced: true,
    state: {
        ...defaultStorePagingProps.state,
        filter: { accountId: null, feature: null, action: 0 } as StoreActivityFilter
    },
    mutations: {
        ...defaultStorePagingProps.mutations,
        setFilter: (state: any, filter: StoreActivityFilter): void => {
            state.filter = filter;
        }
    },
    actions: {
        ...defaultStorePagingProps.actions,
        changeFilter: (context: any, filter: StoreActivityFilter): void => {
            context.commit('setFilter', filter);
        }
    },
    getters: {
        ...defaultStorePagingProps.getters,
        filter: (state: any): StoreActivityFilter => state.filter
    }
};

export default activityModule;
