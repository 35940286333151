import { FEATURES } from '@/modules/promotion/code/model/mixins';
import router from '@/router';

const Main = () => import(/* webpackChunkName: "exoty" */ '@/modules/main/main.vue');
const EmptyRouterView = () => import(/* webpackChunkName: "exoty" */ '@/components/EmptyRouter.vue');
const PromoCodeAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/code/view/add.vue');
const PromoCodeDetail = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/code/view/detail.vue');
const PromoCodeList = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/code/view/index.vue');

const PromoItemAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/item/view/add.vue');
const PromoItemClone = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/item/view/add.vue');
const PromoItemDetail = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/item/view/detail.vue');
const PromoItemEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/item/view/edit.vue');
const PromoItemList = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/item/view/index.vue');
const PromoItemTranslate = () => import(/* webpackChunkName:"exoty" */ '@/modules/promotion/item/view/translate.vue');

const PromoPrototypeAdd = () => import(/* webpackChunkName:"exoty" */ '@/modules/promotion/prototype/view/add.vue');
const PromoPrototypeList = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/prototype/view/index.vue');
const PromoPrototypeDetail = () => import(/*webpackChunkName:"exoty"*/ '@/modules/promotion/prototype/view/detail.vue');

const PromoBoosterList = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/booster/view/index.vue');
const PromoBoosterAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/booster/view/add.vue');
const PromoBoosterEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/booster/view/edit.vue');
const PromoBoosterDetail = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/booster/view/detail.vue');

const PromoPlanList = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/plan/view/index.vue');
const PromoPlanAdd = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/plan/view/add.vue');
const PromoPlanEdit = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/plan/view/edit.vue');
const PromoPlanDetail = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/plan/view/detail.vue');
const PromoPlanTranslate = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/plan/view/translate.vue');

const PromoCycleList = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/cycle/view/index.vue');
const PromoCycleDetail = () => import(/* webpackChunkName: "exoty" */ '@/modules/promotion/cycle/view/detail.vue');

export default [
    {
        path: '/app/promotion/',
        component: Main,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'item/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'PromotionItemList',
                        component: PromoItemList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'PromotionItemAdd',
                        component: PromoItemAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id',
                        name: 'PromotionItemEdit',
                        component: PromoItemEdit,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/clone',
                        name: 'PromotionItemClone',
                        component: PromoItemClone,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/detail',
                        name: 'PromotionItemDetail',
                        component: PromoItemDetail,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/translate',
                        name: 'PromotionItemTranslate',
                        component: PromoItemTranslate,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'code/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'PromotionCodeList',
                        component: PromoCodeList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'PromotionCodeAdd',
                        component: PromoCodeAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/detail',
                        name: 'PromotionCodeDetail',
                        component: PromoCodeDetail,
                        meta: { requiresAuth: true }
                    }
                ]
            },
            {
                path: 'prototype/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'PromotionPrototypeList',
                        component: PromoPrototypeList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'PromotionPrototypeAdd',
                        component: PromoPrototypeAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/clone',
                        name: 'PromotionPrototypeClone',
                        component: PromoPrototypeAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/detail',
                        name: 'PromotionPrototypeDetail',
                        component: PromoPrototypeDetail,
                        meta: { requiresAuth: true }
                    }
                ]
            },

            {
                path: 'booster/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'PromotionBoosterList',
                        component: PromoBoosterList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'PromotionBoosterAdd',
                        component: PromoBoosterAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/edit',
                        name: 'PromotionBoosterEdit',
                        component: PromoBoosterEdit,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/detail',
                        name: 'PromotionBoosterDetail',
                        component: PromoBoosterDetail,
                        meta: { requiresAuth: true }
                    }
                ]
            },

            {
                path: 'plan/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'PromotionPlanList',
                        component: PromoPlanList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'add',
                        name: 'PromotionPlanAdd',
                        component: PromoPlanAdd,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/edit',
                        name: 'PromotionPlanEdit',
                        component: PromoPlanEdit,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/detail',
                        name: 'PromotionPlanDetail',
                        component: PromoPlanDetail,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/translate',
                        name: 'PromotionPlanTranslate',
                        component: PromoPlanTranslate,
                        meta: { requiresAuth: true }
                    }
                ]
            },

            {
                path: 'cycle/',
                component: EmptyRouterView,
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        name: 'PromotionCycleList',
                        component: PromoCycleList,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: ':id/detail',
                        name: 'PromotionCycleDetail',
                        component: PromoCycleDetail,
                        meta: { requiresAuth: true }
                    }
                ]
            }
        ]
    }
];

export const PROMOTION_ROUTER = {
    ITEM: {
        INDEX: () => router.resolve({ name: 'PromotionItemList' }),
        ADD: () => router.resolve({ name: 'PromotionItemAdd' }),
        EDIT: (id: any) => router.resolve({ name: 'PromotionItemEdit', params: { id: id } }),
        CLONE: (id: any) => router.resolve({ name: 'PromotionItemClone', params: { id: id } }),
        DETAIL: (id: any) => router.resolve({ name: 'PromotionItemDetail', params: { id: id } }),
        TRANSLATE: (id: any) => router.resolve({ name: 'PromotionItemTranslate', params: { id: id } })
    },
    BOOSTER: {
        INDEX: () => router.resolve({ name: 'PromotionBoosterList' }),
        ADD: () => router.resolve({ name: 'PromotionBoosterAdd' }),
        EDIT: (id: any) => router.resolve({ name: 'PromotionBoosterEdit', params: { id: id } }),
        DETAIL: (id: any) => router.resolve({ name: 'PromotionBoosterDetail', params: { id: id } }),
        TRANSLATE: (id: any) => router.resolve({ name: 'PromotionBoosterTranslate', params: { id: id } })
    },
    PLAN: {
        INDEX: () => router.resolve({ name: 'PromotionPlanList' }),
        ADD: () => router.resolve({ name: 'PromotionPlanAdd' }),
        EDIT: (id: any) => router.resolve({ name: 'PromotionPlanEdit', params: { id: id } }),
        DETAIL: (id: any) => router.resolve({ name: 'PromotionPlanDetail', params: { id: id } }),
        TRANSLATE: (id: any) => router.resolve({ name: 'PromotionPlanTranslate', params: { id: id } })
    },
    PROTOTYPE: {
        INDEX: () => router.resolve({ name: 'PromotionPrototypeList' }),
        ADD: () => router.resolve({ name: 'PromotionPrototypeAdd' }),
        CLONE: (id: any) => router.resolve({ name: 'PromotionPrototypeClone', params: { id: id } }),
        DETAIL: (id: any) => router.resolve({ name: 'PromotionPrototypeDetail', params: { id: id } })
    },
    CODE: {
        INDEX: () => router.resolve({ name: 'PromotionCodeList' }),
        ADD: () => router.resolve({ name: 'PromotionCodeAdd', query: { feature: 10, data: 0 } }),
        ADD_TO_FEATURE: (feature: FEATURES, data: number) =>
            router.resolve({ name: 'PromotionCodeAdd', query: { feature, data } }),
        DETAIL: (id: any) => router.resolve({ name: 'PromotionCodeDetail', params: { id: id } })
    },
    CYCLE: {
        INDEX: () => router.resolve({ name: 'PromotionCycleList' }),
        DETAIL: (id: any) => router.resolve({ name: 'PromotionCycleDetail', params: { id: id } })
    }
};
